@import
  "minima/skins/dark",
  "minima/initialize";


body {
    font: 400 16px/1.5 "Open Sans Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
}


.squigglevision {
  animation: squigglevision 0.4s infinite alternate;
  letter-spacing: 2rem;
  text-indent: 2rem;
  filter: invert(100%) brightness(80%);
}

@keyframes squigglevision {
  0% {
    filter: url("#turbulence-1");
  }
  25% {
    filter: url("#turbulence-2");
  }
  50% {
    filter: url("#turbulence-3");
  }
  75% {
    filter: url("#turbulence-4");
  }
  100% {
    filter: url("#turbulence-5");
  }
}

.logo {
  width: 600px;
  height: auto;
  margin: auto;
}

.banner {
  font-family: Amatic SC, cursive;
  text-align: center;
  font-size: 72px;
}

.footer-col-wrapper {
  align-items: end;
}

.page-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

}

.social-media-list li a {
  border: none;
}
// @media (prefers-color-scheme: dark) {
//   body {
//     background: #2c2c2c;
//     color: #c0c0c0;
//   }
//   img.squigglevision {
//       filter: invert(100%) brightness(80%);
//   }
// }
